





































import Vue from 'vue';
import Spinner from './Spinner.vue';

interface Data {
  selectedLanguage: string | null,
  selectedTag: string | null,
  isLoading: boolean,
  iFrameSrc: string | null,
}

export default Vue.extend({
  data: (): Data => ({
    selectedLanguage: null,
    selectedTag: null,
    isLoading: false,
    iFrameSrc: null,
  }),
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    schemaDri: String,
  },
  components: {
    Spinner,
  },
  mounted() {
    window.addEventListener('message', (evt: MessageEvent) => {
      // ignore all messages that are created from this window
      if (evt.source === window)
        return;

      const iframe = this.iframe;

      switch (evt.data?.type) {
        case 'update':
          this.isLoading = false;
          if (iframe)
            iframe.style.height = evt.data.documentHeight + 'px';
          break;
        case 'data':
          this.$emit('change', evt.data.evt);
          break;
      }
    });
  },
  methods: {
    async reload() {
      if (!this.schemaDri)
        return;

      this.isLoading = true;

      const { searchParams: windowParams } = new URL(window.location.href);
      // one can specify a different form url via URL params
      const url = new URL(windowParams.get('FORM_URL') || 'https://soya-form.ownyourdata.eu');
      const { searchParams: params } = url;

      params.append('viewMode', 'embedded');
      params.append('schemaDri', this.schemaDri);
      params.append('data', JSON.stringify(this.data));

      this.iFrameSrc = url.toString();
    },
  },
  watch: {
    schemaDri() {
      this.reload();
    },
    data() {
      this.iframe?.contentWindow?.postMessage({
        type: 'data',
        data: this.data,
      }, '*');
    },
    iFrameSrc() {
      this.$emit('iFrameSrcChange', this.iFrameSrc);
    }
  },
  computed: {
    hasSchemaDri(): boolean {
      return !!this.schemaDri;
    },
    iframe(): HTMLIFrameElement | undefined {
      return this.$refs.iframe as unknown as HTMLIFrameElement;
    }
  },
})
